import { DocumentNode } from '@apollo/client'
import { OperationError } from '@local/ec-app/apollo'

import { Employee, LocalityCodeOption } from '../employee/domain'
import {
  PersonalProfileSettings,
  PersonalProfileSettingsValues
} from '../employee/profile/personal/settings/Domain'
import {
  Addresses,
  Basic,
  Contact,
  EmergencyContact,
  PersonalProfile,
  SocialSecurity
} from '../employee/profile/personal/domain'
import {
  DocumentDetail,
  DocumentSignature,
  W4CompletionStatus
} from '../employee/onboarding/domain'
import type { Document } from '../employee/onboarding/domain'
import { parseSocialSecurity } from '../SocialSecuritySerializer'
import {
  Options,
  I9FormResponse,
  I9Form
} from '../employee/onboarding/domain/TaxForms'
import * as Queries from '@local/api/queries'
import { useRestClient } from '@local/api/ApolloClientsProvider'
import { HR_CONTENT_TYPE } from '../employee/onboarding/shared/apiContentTypes'
import { ERROR_STATE } from '../error/ErrorStates'

export type PersonalProfileQueryResponse = {
  personalProfile: Pick<PersonalProfile, 'links' | 'options'> & {
    personalProfile: Omit<PersonalProfile, 'links' | 'options'>
  }
}

export type RestClient = ReturnType<typeof useRestClient>

const makeRestApi = (restClient: RestClient) => {
  type Args<V, R, D extends object> = {
    query: DocumentNode
    variables: V
    context?: { headers: Record<string, string> }
    postProcess: (data: D) => R
  }

  const restApiCall = async <Variables, Result, Data extends object>(
    args: Args<Variables, Result, Data>
  ) => {
    const { query, variables, context, postProcess } = args

    const resp = await restClient.query<Data>({ query, context, variables })

    try {
      return postProcess(resp.data)
    } catch (error) {
      throw new OperationError({
        query,
        result: resp.data,
        originalError: error as Error
      })
    }
  }

  return {
    findSocialSecurityByEmployeeId: (url: string, confirm: boolean) => {
      type Data = { socialSecurity: SocialSecurity & { _links: any } }

      return restApiCall({
        query: Queries.FIND_SOCIAL_SECURITY_BY_EMPLOYEE_ID,
        variables: {
          url: url.replace('{confirm}', confirm.toString())
        },
        postProcess: (data: Data) => parseSocialSecurity(data.socialSecurity)
      })
    },
    findEmployee: (companyCode: string, employeeUuid: string) => {
      type Data = { employee: Employee }

      return restApiCall({
        query: Queries.FIND_EMPLOYEE,
        variables: { companyCode, employeeUuid },
        postProcess: (data: Data) => Employee.of(data.employee)
      })
    },
    getLocalityGnisCodes: (companyCode: string, zipCode: string) => {
      type Data = { codes: LocalityCodeOption[] }

      return restApiCall({
        query: Queries.GET_LOCALITY_GNIS_CODES,
        variables: { companyCode, zipCode },
        postProcess: (data: Data) => LocalityCodeOption.of(data.codes)
      })
    },
    findPersonalProfileSettings: (
      companyCode: string,
      employeeUuid: string
    ) => {
      type Data = { settings: PersonalProfileSettings }

      return restApiCall({
        query: Queries.FIND_PERSONAL_PROFILE_SETTINGS,
        variables: { companyCode, employeeUuid },
        postProcess: (data: Data) => PersonalProfileSettings.of(data.settings)
      })
    },
    findPersonalProfileByEmployeeId: (
      companyCode: string,
      employeeUuid: string
    ) => {
      return restApiCall({
        query: Queries.FIND_PERSONAL_PROFILE_BY_EMPLOYEE_ID,
        variables: { companyCode, employeeUuid },
        postProcess: (data: PersonalProfileQueryResponse) => {
          const { personalProfile, options, links } = data.personalProfile

          return PersonalProfile.of({
            ...personalProfile,
            companyCode,
            employeeId: employeeUuid,
            options,
            links
          })
        }
      })
    },
    savePersonalProfileSettings: async (
      companyCode: string,
      employeeUuid: string,
      settings: PersonalProfileSettingsValues
    ) => {
      await restClient.mutate({
        mutation: Queries.SAVE_PERSONAL_PROFILE_SETTINGS,
        variables: { companyCode, employeeUuid, input: settings }
      })
    },
    saveAddresses: async (url: string, addresses: Addresses) => {
      await restClient.mutate({
        mutation: Queries.SAVE_ADDRESSES,
        variables: { url, input: addresses }
      })
    },
    saveBasic: async (url: string, basic: Basic) => {
      await restClient.mutate({
        mutation: Queries.SAVE_BASIC,
        variables: { url, input: basic }
      })
    },
    saveProfile: async (url: string, personalProfile: PersonalProfile) => {
      const { employeeId, basic, contact, addresses, emergencyContacts } =
        personalProfile

      const input = {
        uuid: employeeId,
        basic,
        contact,
        addresses,
        emergencyContacts
      }

      await restClient.mutate({
        mutation: Queries.SAVE_PROFILE,
        variables: { url, input }
      })
    },
    saveEmergencyContacts: async (
      url: string,
      emergencyContacts: EmergencyContact[]
    ) => {
      await restClient.mutate({
        mutation: Queries.SAVE_EMERGENCY_CONTACTS,
        variables: { url, input: emergencyContacts }
      })
    },
    saveContactInfo: async (url: string, contact: Contact) => {
      await restClient.mutate({
        mutation: Queries.SAVE_CONTACT_INFO,
        variables: { url, input: contact }
      })
    },
    syncToToastPos: async (url: string) => {
      await restClient.mutate({
        mutation: Queries.SYNC_TO_POS,
        variables: { url, input: {} }
      })
    },
    saveDocumentSignature: async (
      url: string,
      documentSignature: DocumentSignature
    ) => {
      await restClient.mutate({
        mutation: Queries.SAVE_DOCUMENT_SIGNATURE,
        variables: { url, input: documentSignature }
      })
    },
    changePassword: async (
      companyCode: string,
      userUuid: string,
      newPassword: string
    ) => {
      await restClient.mutate({
        mutation: Queries.CHANGE_PASSWORD,
        variables: { companyCode, userUuid, input: { newPassword } }
      })
    },
    changeUsernameAndEmail: async (
      companyCode: string,
      userUuid: string,
      username: string | null,
      email: string
    ) => {
      await restClient.mutate({
        mutation: Queries.CHANGE_USERNAME_AND_EMAIL,
        variables: { companyCode, userUuid, input: { username, email } }
      })
    },

    sendWelcomeEmail: async (companyCode: string, employeeUuid: string) => {
      await restClient.mutate({
        mutation: Queries.SEND_WELCOME_EMAIL,
        variables: { companyCode, employeeUuid, input: {} }
      })
    },
    sendWelcomeEmailHr: async (companyCode: string, userUuid: string) => {
      await restClient.mutate({
        mutation: Queries.SEND_WELCOME_EMAIL_HR,
        variables: { companyCode, userUuid, input: {} }
      })
    },
    sendInvitationEmails: async (companyCode: string, userUuids: string[]) => {
      await restClient.mutate({
        mutation: Queries.SEND_INVITATION_EMAILS,
        variables: { companyCode, input: { userUuids } }
      })
    },
    getRequiredDocuments: (companyCode: string, employeeUuid: string) => {
      type Data = { documents: Document[] }

      return restApiCall({
        query: Queries.GET_REQUIRED_DOCUMENTS,
        variables: { companyCode, employeeUuid },
        postProcess: (data: Data) => data.documents
      })
    },
    getEmployeeDocument: async (
      companyCode: string,
      employeeUuid: string,
      docId: number
    ) => {
      // note: should this call be wrapped?
      type Data = { document: DocumentDetail }

      const resp = await restClient.query<Data>({
        query: Queries.GET_EMPLOYEE_DOCUMENT,
        variables: { companyCode, employeeUuid, docId }
      })
      return resp.data.document
    },
    getEmployeeI9FormOptions: (companyCode: string, employeeUuid: string) => {
      type Data = {
        result: { options: Options; isUsingEverify: boolean }
      }
      return restApiCall({
        query: Queries.GET_EMPLOYEE_I9_FORM_OPTIONS,
        variables: { companyCode, employeeUuid },
        postProcess: (data: Data) => ({
          options: Options.of(data.result.options),
          isUsingEverify: data.result.isUsingEverify
        })
      })
    },
    getEmployeeI9Form: (companyCode: string, employeeUuid: string) => {
      type Data = { form: I9FormResponse }

      return restApiCall({
        query: Queries.GET_EMPLOYEE_I9_FORM,
        variables: { companyCode, employeeUuid },
        postProcess: (data: Data) => I9FormResponse.of(data.form)
      })
    },
    completeW4: async (companyCode: string, employeeUuid: string) => {
      const input = JSON.stringify({})
      await restClient.mutate({
        mutation: Queries.COMPLETE_W4,
        variables: { companyCode, employeeUuid, input },
        context: { headers: { Accept: HR_CONTENT_TYPE } }
      })
    },
    saveI9: async (companyCode: string, employeeUuid: string, form: I9Form) => {
      await restClient.mutate({
        mutation: Queries.SAVE_I9,
        variables: { companyCode, employeeUuid, input: form }
      })
    },
    editI9: async (companyCode: string, employeeUuid: string, form: I9Form) => {
      await restClient.mutate({
        mutation: Queries.EDIT_I9,
        variables: { companyCode, employeeUuid, input: form }
      })
    },
    getRegistration: (companyCode: string, employeeUuid: string) => {
      type Data = { registration: { registrationUrl: string } }

      return restApiCall({
        query: Queries.GET_REGISTRATION,
        variables: { companyCode, employeeUuid },
        context: { headers: { Accept: HR_CONTENT_TYPE } },
        postProcess: ({ registration }: Data) => {
          if (!registration.registrationUrl) {
            throw new Error(ERROR_STATE.REGISTRATION_URL_MISSING_ERROR)
          }

          return registration
        }
      })
    },

    getW4CompletionStatus: (companyCode: string, employeeUuid: string) => {
      type Data = { status: W4CompletionStatus }
      return restApiCall({
        query: Queries.GET_W4_COMPLETION_STATUS,
        variables: { companyCode, employeeUuid },
        context: { headers: { Accept: HR_CONTENT_TYPE } },
        postProcess: ({ status }: Data) => W4CompletionStatus.of(status)
      })
    },
    getNewHireTechUrl: async (companyCode: string, employeeUuid: string) => {
      type Data = { hireTech: { hireTechUrl: string } }
      return restApiCall({
        query: Queries.GET_HIRE_TECH_URL,
        variables: { companyCode, employeeUuid },
        postProcess: (data: Data) => {
          if (!data.hireTech?.hireTechUrl) {
            throw new Error(ERROR_STATE.MISSING_HIRE_TECH_ERROR)
          }

          return data.hireTech
        }
      })
    }
  }
}

export default makeRestApi
