import { DateTime } from 'luxon'

export const getDateAsDigits = (date?: DateTime | string | null) => {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    // remove whitespace
    return date.replace(/\s/g, '')
  }
  const strVal = date.toFormat('MM/dd/yyyy')
  return strVal.replace(/\D/g, '')
}

// Takes either a formatted date string or a DateTime object
// and converts it to a DateTime objection
export const toDate = (value?: DateTime | string | null) => {
  if (value instanceof DateTime) {
    return value
  } else if (value) {
    const trimmedValue = value.toString().trim()
    if (trimmedValue === '/  /') {
      return null
    }
    return DateTime.fromFormat(trimmedValue, 'M/d/yyyy')
  } else {
    return null
  }
}

export const quarterOptions = () => {
  return [
    { value: 1, label: 1 },
    { value: 1, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 0, label: 'YTD' }
  ]
}

export const yearOptions = () => {
  const currentYear = new Date().getFullYear()

  return [
    { value: currentYear, label: currentYear },
    { value: currentYear - 1, label: currentYear - 1 },
    { value: currentYear - 2, label: currentYear - 2 },
    { value: currentYear - 3, label: currentYear - 3 }
  ]
}

export const getQuarterDates = (
  quarter: number | undefined,
  year: number | undefined
) => {
  if (quarter == undefined || year == undefined) {
    return
  }

  if (quarter === 0) {
    const startDate = new Date(year, 0, 1)
    const endDate = new Date(year, 11, 31)
    return { startDate, endDate }
  }

  const startMonth = (quarter - 1) * 3
  const startDate = new Date(year, startMonth, 1)
  const endDate = new Date(year, startMonth + 3, 0)

  return { startDate, endDate }
}
