import * as React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from '@toasttab/buffet-pui-modal'
import { SelectField, DateRangePickerField } from '@toasttab/buffet-pui-forms'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useBuffetContext } from '@toasttab/buffet-pui-context-provider'

import { ModalHooksFor } from '@local/modal-provider'

import { useTranslation } from 'react-i18next'
import { usePayHistoryReportStore } from '../../PayHistoryReport/stores/PayHistoryReportStore'
import { PayHistoryReportType } from '@local/generated/graphql'
import { useEmployeeId } from '../../../../hooks'
import { useCurrentUser } from '@local/ec-app'
import {
  getQuarterDates,
  quarterOptions,
  yearOptions
} from '../../../../../shared/formHelpers'

const { useOpenModal, useIsModalOpen, useCloseModal } = ModalHooksFor<string>(
  'employee-pay-history'
)

export const usePayHistoryReportModal = () => {
  const open = useOpenModal()
  return {
    open
  }
}

type PayHistoryReportForm = {
  from?: Date
  to?: Date
  quarter?: number
  year?: number
  reportType: PayHistoryReportType
}

const PayHistoryReportSchema = Yup.object().shape({
  from: Yup.date().when(['quarter', 'year'], {
    is: (quarter, year) => !quarter && !year,
    then: Yup.date().required(),
    otherwise: Yup.date()
  }),
  to: Yup.date().when(['quarter', 'year'], {
    is: (quarter, year) => !quarter && !year,
    then: Yup.date().required(),
    otherwise: Yup.date()
  }),
  reportType: Yup.mixed().required()
})

const FORM_NAME = 'pay-history-report-form'

export const PayHistoryReportModal = () => {
  const isOpen = useIsModalOpen()

  if (!isOpen) return null

  return <PayHistoryReportModalInner />
}

const initialValues: PayHistoryReportForm = {
  from: undefined,
  to: undefined,
  quarter: undefined,
  year: undefined,
  reportType: PayHistoryReportType.DETAIL
}

export const PayHistoryReportModalInner = () => {
  const { t } = useTranslation('employees')
  const { locale } = useBuffetContext()
  const { isUserSuperAdminLight, isUserSuperAdmin } = useCurrentUser()
  const isToaster = isUserSuperAdmin || isUserSuperAdminLight

  const openReport = usePayHistoryReportStore((store) => store.openReport)
  const employeeId = useEmployeeId()

  const closeModal = useCloseModal()

  return (
    <Formik
      validationSchema={PayHistoryReportSchema}
      initialValues={initialValues}
      onReset={closeModal}
      onSubmit={(values) => {
        const {
          from,
          to,
          quarter,
          year,
          reportType = PayHistoryReportType.DETAIL
        } = values
        const quarterDates = getQuarterDates(quarter, year)

        closeModal()
        openReport({
          employeeUuid: employeeId,
          from: (quarterDates?.startDate ?? from!!).toISOString(),
          to: (quarterDates?.endDate ?? to!!).toISOString(),
          reportType,
          feinId: undefined
        })
      }}
    >
      <Modal
        position='pin-right'
        isOpen={true}
        shouldCloseOnEsc
        onRequestClose={closeModal}
      >
        <ModalHeader>{t('payHistoryReport')}</ModalHeader>
        <ModalBody>
          <Form id={FORM_NAME}>
            <p className='type-default'>{t('payHistoryReportDescription')}</p>
            <div className='space-y-4'>
              <DateRangePickerField
                label={t('dateRange')}
                locale={locale}
                placeholder={t('dateRange')}
                testId='DateRangePicker'
                name='dates'
                fromFieldName='from'
                toFieldName='to'
              />
              {isToaster && (
                <div>
                  <p className='type-default'>
                    {t('payHistoryReportDescriptionToaster')}
                  </p>
                  <div className='grid grid-cols-2 gap-4'>
                    <SelectField
                      name='quarter'
                      label={t('quarter')}
                      options={quarterOptions()}
                    />
                    <SelectField
                      name='year'
                      label={t('year')}
                      options={yearOptions()}
                    />
                  </div>
                </div>
              )}
              <SelectField
                name='reportType'
                label={t('reportType')}
                options={[
                  {
                    label: t('detail') as string,
                    value: PayHistoryReportType.DETAIL
                  },
                  {
                    label: t('summary') as string,
                    value: PayHistoryReportType.SUMMARY
                  }
                ]}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div className='flex w-full space-x-4'>
            <div className='flex-grow'>
              <Button
                variant='link'
                form={FORM_NAME}
                type='reset'
                className='w-full'
              >
                {t('cancel')}
              </Button>
            </div>
            <div className='flex-grow'>
              <Button type='submit' form={FORM_NAME} className='w-full'>
                {t('viewReport')}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </Formik>
  )
}
