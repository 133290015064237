import { gql } from '@apollo/client'

const makeDynamicRestMutation = (
  operationName: string,
  method: 'POST' | 'PUT' | 'PATCH'
) => gql`
  mutation ${operationName}($url: ID!, $input: any) {
    save(input: $input, url: $url) @rest(path: "/{args.url}", method: "${method}") {
      NoResponse
    }
  }
`

export const FIND_USER_BY_COMPANY_CODE_AND_EMPLOYEE_UUID = gql`
  query FindUserByCompanyCodeAndEmployeeUuid(
    $companyCode: ID!
    $employeeUuid: ID!
  ) {
    findUsers(companyCode: $companyCode, employeeUuid: $employeeUuid) {
      list {
        id
        toastIdentityGuid
        email
        username
        isEmailVerified
      }
    }
  }
`

export const FIND_EMPLOYEE = gql`
  query FindEmployee($companyCode: ID!, $employeeUuid: ID!) {
    employee(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(path: "/{args.companyCode}/employees/{args.employeeUuid}") {
      employeeIsUser
      overview
      navigation
      permissions
    }
  }
`

export const GET_LOCALITY_GNIS_CODES = gql`
  query GetLocalityGnisCodes($companyCode: ID!, $zipCode: ID!) {
    codes(companyCode: $companyCode, zipCode: $zipCode)
      @rest(
        path: "/{args.companyCode}/localityGnisCodes/{args.zipCode}"
        type: "[any]"
      ) {
      zipCode
      gnisCode
      gnisCaption
    }
  }
`

export const FIND_PERSONAL_PROFILE_SETTINGS = gql`
  query FindPersonalProfileSettings($companyCode: ID!, $employeeUuid: ID!) {
    settings(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/profile/settings"
      ) {
      values
      options
      links
    }
  }
`

export const FIND_PERSONAL_PROFILE_BY_EMPLOYEE_ID = gql`
  query FindPersonalProfileByEmployeeId($companyCode: ID!, $employeeUuid: ID!) {
    personalProfile(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(path: "/{args.companyCode}/employees/{args.employeeUuid}/profile") {
      options
      links
      personalProfile
    }
  }
`

export const GET_BANK_ACCOUNTS = gql`
  query GetBankAccounts($companyCode: ID!, $employeeUuid: ID!) {
    bankAccounts(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/bankAccounts"
        type: "[any]"
      ) {
      id
      accountType
      bankName
      accountName
      accountNumber
      routingNumber
      percentage
      amount
      sequence
    }
  }
`

export const FIND_SOCIAL_SECURITY_BY_EMPLOYEE_ID = gql`
  query FindSocialSecurityByEmployeeId($url: ID!) {
    socialSecurity(url: $url) @rest(path: "/{args.url}") {
      number
      auditLog
      _links
    }
  }
`

export const GET_REQUIRED_DOCUMENTS = gql`
  query GetRequiredDocuments($companyCode: ID!, $employeeUuid: ID!) {
    documents(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/required-documents"
        type: "[any]"
      ) {
      id
      name
      signatureRequired
      isComplete
      href
    }
  }
`

export const GET_EMPLOYEE_DOCUMENT = gql`
  query GetEmployeeDocument(
    $companyCode: ID!
    $employeeUuid: ID!
    $docId: ID!
  ) {
    document(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      docId: $docId
    )
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/required-documents/{args.docId}"
      ) {
      id
      name
      signatureRequired
      isComplete
      href
      s3Link
      body
      letterheadS3Link
      nextDocId
      documentS3Link
    }
  }
`

export const GET_EMPLOYEE_I9_FORM_OPTIONS = gql`
  query GetEmployeeI9FormOptions($companyCode: ID!, $employeeUuid: ID!) {
    result(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/forms/i9/options"
      ) {
      options {
        countries
        states
      }
      isUsingEverify
    }
  }
`

export const GET_EMPLOYEE_I9_FORM = gql`
  query GetEmployeeI9Form($companyCode: ID!, $employeeUuid: ID!) {
    form(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/forms/i9/edit"
      ) {
      i9Form
      options
    }
  }
`

export const SAVE_PERSONAL_PROFILE_SETTINGS = gql`
  mutation SavePersonalProfileSettings(
    $companyCode: ID!
    $employeeUuid: ID!
    $input: any
  ) {
    save(input: $input, companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/profile/settings"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($companyCode: ID!, $userUuid: ID!, $input: any) {
    changePassword(
      companyCode: $companyCode
      userUuid: $userUuid
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/human-resources/users/{args.userUuid}/password"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export const CHANGE_USERNAME_AND_EMAIL = gql`
  mutation ChangeUsernameAndEmail(
    $companyCode: ID!
    $userUuid: ID!
    $input: any
  ) {
    changeUsernameAndEmail(
      companyCode: $companyCode
      userUuid: $userUuid
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/human-resources/users/{args.userUuid}"
        method: "PATCH"
      ) {
      NoResponse
    }
  }
`

export const SEND_WELCOME_EMAIL = gql`
  mutation SendWelcomeEmail(
    $companyCode: ID!
    $employeeUuid: ID!
    $input: any
  ) {
    sendWelcomeEmail(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/registration-email"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export const SEND_WELCOME_EMAIL_HR = gql`
  mutation SendWelcomeEmailHr($companyCode: ID!, $userUuid: ID!, $input: any) {
    sendWelcomeEmailHr(
      companyCode: $companyCode
      userUuid: $userUuid
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/human-resources/users/{args.userUuid}/welcome-email"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export const SEND_INVITATION_EMAILS = gql`
  mutation SendInvitationEmails($companyCode: ID!, $input: any) {
    sendInvitationEmails(companyCode: $companyCode, input: $input)
      @rest(
        path: "/{args.companyCode}/human-resources/users/invitation-emails"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export const SEND_MY_TOAST_DOWNLOAD_SMS = gql`
  mutation SendMyToastDownloadSms($input: any) {
    sendMyToastDownloadSms(input: $input)
      @rest(path: "/sms/myToastDownload", method: "POST") {
      NoResponse
    }
  }
`

export const COMPLETE_W4 = gql`
  mutation CompleteW4($companyCode: ID!, $employeeUuid: ID!, $input: any) {
    completeW4(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/human-resources/employees/{args.employeeUuid}/forms/w4/state/complete"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export const GET_REGISTRATION = gql`
  query GetRegistration($companyCode: ID!, $employeeUuid: ID!) {
    registration(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/human-resources/employees/{args.employeeUuid}/w4/registration"
      ) {
      registrationUrl
    }
  }
`

export const GET_W4_COMPLETION_STATUS = gql`
  query GetW4CompletionStatus($companyCode: ID!, $employeeUuid: ID!) {
    status(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/human-resources/employees/{args.employeeUuid}/forms/w4/status"
      ) {
      federal
      state
    }
  }
`

export const GET_PAYMENT_METHOD = gql`
  query GetPaymentMethod($companyCode: ID!, $employeeUuid: ID!) {
    paymentMethod(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/payMethod"
      ) {
      type
      obj
    }
  }
`

export const GET_HIRE_TECH_URL = gql`
  query GetHireTechUrl($companyCode: ID!, $employeeUuid: ID!) {
    hireTech(companyCode: $companyCode, employeeUuid: $employeeUuid)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/hireTech"
      ) {
      hireTechUrl
    }
  }
`

export const GET_REAUTH_EXPIRATION = gql`
  query GetReauthExpiration {
    reauth @rest(path: "/reauthentication") {
      expiration
    }
  }
`

export const SAVE_I9 = gql`
  mutation SaveI9($companyCode: ID!, $employeeUuid: ID!, $input: any) {
    save(companyCode: $companyCode, employeeUuid: $employeeUuid, input: $input)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/forms/i9"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export const EDIT_I9 = gql`
  mutation EditI9($companyCode: ID!, $employeeUuid: ID!, $input: any) {
    save(companyCode: $companyCode, employeeUuid: $employeeUuid, input: $input)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/forms/i9/edited"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export const SAVE_EMPLOYEE_W4 = gql`
  mutation SaveEmployeeW4($companyCode: ID!, $employeeUuid: ID!, $input: any) {
    save(companyCode: $companyCode, employeeUuid: $employeeUuid, input: $input)
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/forms/i9/edited"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ($input: ResetPasswordInput) {
    resetPassword(input: $input)
  }
`

export const SAVE_PAYMENT_METHOD = gql`
  mutation SavePaymentMethod(
    $companyCode: ID!
    $employeeUuid: ID!
    $onboardingChecklist: any
    $input: any
  ) {
    save(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      onboardingChecklist: $onboardingChecklist
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/payMethod?onboardingChecklist={args.onboardingChecklist}"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export const LOG_DOWNLOAD_LINK_SENT = gql`
  mutation LogDownloadLinkSent($employeeId: ID!, $input: any) {
    logDownloadLinkSent(employeeId: $employeeId, input: $input)
      @rest(
        path: "/employees/{args.employeeId}/log/downloadLinkSent"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteBankAccount(
    $companyCode: ID!
    $employeeUuid: ID!
    $bankAccountUuid: ID!
    $onboardingChecklist: any
    $input: any
  ) {
    deleteBankAccount(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      bankAccountUuid: $bankAccountUuid
      onboardingChecklist: $onboardingChecklist
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/bankAccounts/{args.bankAccountUuid}?onboardingChecklist={args.onboardingChecklist}"
        method: "DELETE"
      ) {
      NoResponse
    }
  }
`

export const GET_PLAID_LINK_TOKEN = gql`
  mutation PlaidLinkToken($companyCode: ID!, $employeeUuid: ID!, $input: any) {
    plaidLinkToken(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/plaidLinkToken"
        method: "POST"
      ) {
      token
    }
  }
`

export const SAVE_BANK_ACCOUNT = gql`
  mutation SaveBankAccount(
    $companyCode: ID!
    $employeeUuid: ID!
    $onboardingChecklist: any
    $input: any
  ) {
    saveBankAccount(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      onboardingChecklist: $onboardingChecklist
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/plaidBankAccount?onboardingChecklist={args.onboardingChecklist}"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export const SAVE_BANK_ACCOUNT_SPLIT_AMOUNT = gql`
  mutation SaveBankAccountSplitAmount(
    $companyCode: ID!
    $employeeUuid: ID!
    $input: any
  ) {
    saveBankAccountSplitAmount(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/directDeposit/amount"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export const SAVE_BANK_ACCOUNT_SPLIT_PERCENT = gql`
  mutation SaveBankAccountSplitPercent(
    $companyCode: ID!
    $employeeUuid: ID!
    $input: any
  ) {
    saveBankAccountSplitPercent(
      companyCode: $companyCode
      employeeUuid: $employeeUuid
      input: $input
    )
      @rest(
        path: "/{args.companyCode}/employees/{args.employeeUuid}/directDeposit/percent"
        method: "PUT"
      ) {
      NoResponse
    }
  }
`

export const REPLACE_BANK_ACCOUNT = gql`
  mutation ReplacePlaidBankAccount(
    $employeeUuid: ID!
    $bankAccountId: ID!
    $input: any
  ) {
    replacePlaidBankAccount(
      employeeUuid: $employeeUuid
      bankAccountId: $bankAccountId
      input: $input
    )
      @rest(
        path: "/employees/{args.employeeUuid}/payment/plaid/bankAccounts/{args.bankAccountId}"
        method: "POST"
      ) {
      NoResponse
    }
  }
`

export const SAVE_ADDRESSES = makeDynamicRestMutation('SaveAdresses', 'PUT')
export const SAVE_BASIC = makeDynamicRestMutation('SaveBasic', 'PUT')
export const SAVE_PROFILE = makeDynamicRestMutation('SaveProfile', 'PUT')
export const SAVE_EMERGENCY_CONTACTS = makeDynamicRestMutation(
  'SaveEmergencyContacts',
  'PUT'
)
export const SAVE_CONTACT_INFO = makeDynamicRestMutation(
  'SaveContactInfo',
  'PUT'
)
export const SYNC_TO_POS = makeDynamicRestMutation('SyncToPos', 'POST')
export const SAVE_DOCUMENT_SIGNATURE = makeDynamicRestMutation(
  'SaveDocumentSignature',
  'PUT'
)
