import * as yup from 'yup'
import {
  formDate,
  isoDate,
  strippedString
} from '../../../../shared/yupHelpers'
import { DateTime } from 'luxon'

import {
  SOCIAL_SECURITY_PLACEHOLDER,
  SOCIAL_SECURITY_REGEX
} from './SocialSecurity'
import { DriversLicense } from './DriversLicense'
import { ISO8859_1_SINGLE_BYTE_REGEX } from '../../../utils'

export class Basic {
  // Schema used to validate api response
  static schema = yup
    .object({
      firstName: strippedString.max(255),
      middleName: strippedString.max(255),
      lastName: strippedString.max(255),
      chosenName: strippedString.max(255),
      dateOfBirth: isoDate().notRequired(),
      hasInitialSsn: yup.boolean().default(false).defined(),
      ssnToken: yup.string(),
      socialSecurityNumber: yup.string().equals([SOCIAL_SECURITY_PLACEHOLDER]),
      gender: strippedString,
      maritalStatus: strippedString,
      ethnicity: strippedString,
      eeoRequired: yup.boolean().default(false).defined(),
      veteranStatus: yup
        .array(yup.string().required().defined())
        .defined()
        .default([]),
      disability: strippedString,
      driversLicense: yup.lazy((value) => {
        return value ? DriversLicense.schema : yup.mixed().notRequired()
      }),
      isToastUser: yup.boolean().default(false)
    })
    .defined()

  static SSN_REQUIRED_MSG = 'Social Security number is required'
  static VALID_SSN_MSG = 'Please enter a valid Social Security number'

  static commonFormSchema = {
    firstName: yup
      .string()
      .required('First Name is required')
      .matches(
        ISO8859_1_SINGLE_BYTE_REGEX,
        'First name cannot include emojis or special symbols'
      )
      .max(255),
    middleName: yup
      .string()
      .matches(
        ISO8859_1_SINGLE_BYTE_REGEX,
        'Middle name cannot include emojis or special symbols'
      )
      .nullable()
      .max(255),
    lastName: yup
      .string()
      .required('Last name is required')
      .matches(
        ISO8859_1_SINGLE_BYTE_REGEX,
        'Last name cannot include emojis or special symbols'
      )
      .max(255),
    dateOfBirth: yup.lazy((value: any) => {
      if (value) {
        return formDate(true).test(
          'pastDate',
          'Date of birth must be in the past',
          function (date: DateTime): date is DateTime {
            return date ? date.diffNow('days').days < 0 : true
          }
        )
      } else {
        return yup.mixed().notRequired()
      }
    }),
    driversLicense: yup.lazy((value: any) => {
      if (value) {
        if (value.expirationDate || value.number) {
          return DriversLicense.formSchema
        } else {
          return yup.mixed().notRequired()
        }
      } else {
        return yup.mixed().notRequired()
      }
    })
  }

  static formSchema = Basic.schema.shape({
    ...Basic.commonFormSchema,
    ssnToken: yup.string(),
    socialSecurityNumber: yup
      .string()
      .stripToNull()
      .nullable()
      .when('isToastUser', {
        is: true,
        then: (schema: yup.StringSchema<string | undefined>) =>
          schema
            .test(
              'no-leading-9',
              'Individual Taxpayer Identification Numbers are not accepted. Please enter a Social Security Number or contact your manager for assistance',
              (value) => !value || !value.startsWith('9') // tests gets run but value can be null for rehires
            )
            // allow Toast users to clear out the SSN
            .matches(SOCIAL_SECURITY_REGEX, {
              message: Basic.VALID_SSN_MSG,
              excludeEmptyString: false
            })
      })
      .when('isToastUser', {
        is: false,
        then: (schema: yup.StringSchema<string | undefined>) =>
          schema
            .when('hasInitialSsn', {
              is: true,
              then: (schema: yup.StringSchema<string | undefined>) =>
                schema
                  .test(
                    'no-leading-9',
                    'Individual Taxpayer Identification Numbers are not accepted. Please enter a Social Security Number or contact your manager for assistance',
                    (value) => !value || !value.startsWith('9') // tests gets run but value can be null for rehires
                  )
                  .matches(SOCIAL_SECURITY_REGEX, Basic.VALID_SSN_MSG)
            })
            .when('hasInitialSsn', {
              is: false,
              then: (schema: yup.StringSchema<string | undefined>) =>
                schema
                  .required(Basic.SSN_REQUIRED_MSG)
                  .test(
                    'no-leading-9',
                    'Individual Taxpayer Identification Numbers are not accepted. Please enter a Social Security Number or contact your manager for assistance',
                    (value) => value != null && !value.startsWith('9')
                  )
                  .matches(SOCIAL_SECURITY_REGEX, Basic.VALID_SSN_MSG)
            })
      })
  })

  static onboardingFormSchema = Basic.schema.shape({
    ...Basic.commonFormSchema,
    hasInitialSsn: yup.boolean().default(false).defined(),
    ssnToken: yup.string(),
    socialSecurityNumber: yup
      .string()
      .stripToNull()
      .nullable()
      .matches(SOCIAL_SECURITY_REGEX, Basic.VALID_SSN_MSG)
      .when('hasInitialSsn', {
        is: false,
        then: (schema: any) =>
          schema
            .required(Basic.SSN_REQUIRED_MSG)
            // Disallow the '•••-••-••••' on the onboarding form, since it is used as placeholder
            .notOneOf([SOCIAL_SECURITY_PLACEHOLDER], Basic.SSN_REQUIRED_MSG)
      }),
    eeoRequired: yup.boolean().default(false).defined(),
    gender: yup
      .string()
      .nullable()
      .when('eeoRequired', {
        is: true,
        then: (schema: any) => schema.required('This is a required field')
      }),
    ethnicity: yup
      .string()
      .nullable()
      .when('eeoRequired', {
        is: true,
        then: (schema: any) => schema.required('This is a required field')
      })
  })

  static of(obj: any): Basic {
    return Basic.copyOf(
      <Basic>Basic.formSchema.validateSync(obj, { abortEarly: false })
    )
  }

  static copyOf(valid: Basic): Basic {
    return valid
  }

  public isToastUser: boolean = false

  constructor(
    readonly firstName: string,
    readonly middleName: string | null,
    readonly lastName: string,
    readonly chosenName: string | null,
    readonly dateOfBirth: DateTime | string | null,
    readonly socialSecurityNumber: string | null,
    readonly ssnToken: string | null,
    readonly hasInitialSsn: boolean | null,
    readonly gender: string | null,
    readonly eeoRequired: boolean | null,
    readonly maritalStatus: string | null,
    readonly ethnicity: string | null,
    readonly veteranStatus: Array<string>,
    readonly disability: string | null,
    readonly driversLicense: DriversLicense | null,
    isToastUser: boolean = false
  ) {
    this.isToastUser = isToastUser
  }
}
